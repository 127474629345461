<template>
    <div>
        <!-- <p class="text-right underline text-brandPurple font-semibold my-2 cursor-pointer mr-4" @click="$emit('next')">Skip</p> -->
      <div class=" mb-4 mt-6 px-4">
          <h2 class="Business font-bold text-lg leading-8">Enter Business Info</h2>
          <p class="text-sm font-light leading-8">Tell us about this business</p>
      </div>
      <form @submit.prevent="editBusinesssInfo ? updateBusinessInfo() : enterBusinessInfo()">
          <div class="mb-4 px-4">
              <Input label="Business registration number" v-model="businessInfo.registrationNumber" :validation="rules.registrationNumber"
               required placeholder="0123456" type="number" errorText="Business registration number is required" />
          </div>
          <!-- <div class="mb-4 px-4">
              <Input label="Registered business name" v-model="businessInfo.registeredBusinessName" :validation="rules.registeredBusinessName"
               required placeholder="xxxxx" errorText="Business registration name is required" />
          </div> -->
          <div class="mb-4 px-4">
              <Select v-model="businessInfo.registeredBusinessType" class="mt-5" width="w-full" label="label"
                  formLabel="Business type" placeholder="Select a business type" :reduce="(type) => type.value"
                  :options="businessType" required errorText="Business type is required"
                  :validation="rules.registeredBusinessType" />
          </div>
          <div class="px-4 mb-4">
              <Upload    label="Certificate of incorporation" class="mt-5" @upload="(e) => businessInfo.incorporationCertificateFileId= e.id"
                  :fileType="['pdf', 'jpg', 'jpeg', 'png']" :validation="rules.incorporationCertificateFileId"
                  errorText="Certificate of incorporation is required">
                  <template #desc>
                      <p class="text-xs text-grey mt-1">
                          Upload 1-3 documents of your payment invoice (PDF, PNG, or JPEG) - max 5MB each
                      </p>
                  </template>
              </Upload>
          </div>
          <div class="px-4 mb-4">
              <Upload  multiple   label="Memorandum & Articles of Association" class="mt-5"  v-model="businessInfo.memartFileId"
                  :fileType="['pdf', 'jpg', 'jpeg', 'png']" :validation="rules.memartFileId"
                  errorText="Memorandum & Articles of Association is required">
                  <template #desc>
                      <p class="text-xs text-grey mt-1">
                          Upload 1-3 documents of your payment invoice (PDF, PNG, or JPEG) - max 5MB each
                      </p>
                  </template>
              </Upload>
          </div>
          <div class="px-4 mb-4">
              <Upload    label="Operating license (optional)" class="mt-5"  @upload="(e) => businessInfo.operatingLicenseFileId = e.id"
                  :fileType="['pdf', 'jpg', 'jpeg', 'png']" :validation="rules.operatingLicenseFileId"
                  errorText="Operating license  is required">
                  <template #desc>
                      <p class="text-xs text-grey mt-1">
                          Upload 1-3 documents of your payment invoice (PDF, PNG, or JPEG) - max 5MB each
                      </p>
                  </template>
              </Upload>
          </div>
          <div class="mb-4 px-4">
            <GoogleAutocomplete v-model="businessInfo.address.main" @update="getFullAddressDetails" label="Business Address"
            class="mt-5" placeholder="What is your business address" required />
          </div>
         <div class="mb-4 px-4">
            <Input v-model="businessInfo.address.state" label="State" class="mt-5" placeholder="Enter state" width="100%"
            required :validation="rules.state" errorText="State is required" />
         </div>
         <div class="mb-4 px-4">
            <Input v-model="businessInfo.address.lga" label="LGA / City" class="mt-5" placeholder="Enter city" width="100%"
            required :validation="rules.lga" errorText="LGA/City is required" />
         </div>
            
          <div class="mb-4 px-4">
              <Upload  multiple  label="Proof of address" class="mt-5"  v-model="businessInfo.proofOfAddressFileId"
                  :fileType="['pdf', 'jpg', 'jpeg', 'png']" :validation="rules.proofOfAddressFileId"
                  errorText="Director's proof of address is required">
                  <template #desc>
                      <p class="text-xs text-grey mt-1">
                          Upload 1-3 documents of your payment invoice (PDF, PNG, or JPEG) - max 5MB each
                      </p>
                  </template>
              </Upload>
          </div>
          <div class="continue-Btn w-full flex justify-end items-center  p-2 text-right h-20">
            
              <Button :disabled="disableButton" :loading="businessInfoLoading" text="CONTINUE" type="submit"    v-if="!editBusinesssInfo"/>
              <Button outline v-else text="Update business info"  p="p-2"  :loading="businessInfoLoading" :disabled="disableButton" type="submit" />


          </div>
      </form>
    </div>
  </template>
  
  <script>
  import { Input,  Select, Upload, GoogleAutocomplete  } from '@/UI/Input';
  import { Button } from '@/UI/Button';
  import {BUSINESS_WIRE_REGISTRATION, UPDATE_BUSINESS_WIRE_REGISTRATION} from '@/utils/api/wire'
  import {mapActions} from 'vuex'

  
  export default {
      components: {
          Input,
          Select,
          Button,
          Upload,
          GoogleAutocomplete 
      },
      props: {
      wireAcctData: {
        type: Object,
        required: true
      },
      businessInfoExt:{
        type: Object,
        default:() => {}
      }
    },
    data() {
      return {
        businessType: [
          { label: "Sole proprietorship", value: "SOLE_PROPRIETORSHIP" },
          { label: "Limited Liability Company", value: "LLC" }
        ],
        businessInfo:{
            id:'',
          registrationNumber: "",
          registeredBusinessType:"",
        //   registeredBusinessName: "",
          incorporationCertificateFileId: "",
          operatingLicenseFileId: "", 
          memartFileId: "",
          proofOfAddressFileId: "",
          address: {
              main: "",
              additionalInformation: "",
              state: "",
              lga: "",
              country: ""
          },
        },
        businessInfoLoading: false,
        editBusinesssInfo: false
      };
    },
    computed: {
      rules() {
        return {
          registrationNumber: this.businessInfo.registrationNumber.length > 0,
          registeredBusinessType: this.businessInfo.registeredBusinessType.length > 0,
          incorporationCertificateFileId: this.businessInfo.incorporationCertificateFileId.length > 0,
          memartFileId: this.businessInfo.memartFileId.length > 0,
        //   registeredBusinessName: this.businessInfo.registeredBusinessName.length > 0,
          proofOfAddressFileId: this.businessInfo.proofOfAddressFileId.length > 0,
          address: this.businessInfo.address.main.length !== 0,
          state: this.businessInfo.address.state.length !== 0,
          lga: this.businessInfo.address.lga.length !== 0,
        //   operatingLicenseFileId: this.businessInfo.operatingLicenseFileId.length > 0
        };
      },
      disableButton() {
            return Object.values(this.rules).includes(false);
    },
    },
    methods: {
      ...mapActions('notification', ['showAlert']),

      enterBusinessInfo() {
          const wireAccountId = this.wireAcctData?.id;
          const wireAccountIdName= this.wireAcctData?.name
          
          if (!wireAccountId) {
         
          return;
        }
        this.businessInfoLoading = true;
  
        BUSINESS_WIRE_REGISTRATION({
          data: this.businessInfo,
          wireAccountId: wireAccountId
        })
        .then(response => {
          this.businessInfoLoading = false;
          this.$emit('next');
          let dataObj = response.data.data
          dataObj.address = this.businessInfo.address 
          this.$emit('setBusinessInfo', dataObj)
        })
        .catch(error => {
          this.businessInfoLoading = false;
        })
       
      },
      updateBusinessInfo(){
        this.businessInfoLoading = true; 
        UPDATE_BUSINESS_WIRE_REGISTRATION(this.businessInfo)
            .then((response) => {
                this.businessInfoLoading = false;
                this.$emit('next');
                let dataObj = response.data.data
                dataObj.address = this.businessInfo.address 
                this.$emit('setBusinessInfo', dataObj)
            }).catch(() => {
                this.businessInfoLoading = false;
            })
      },
      next() {
        this.$emit('next');
      },
      getFullAddressDetails(address) {
            this.businessInfo.address.lga = address.lga;
            this.businessInfo.address.state = address.state;
            this.businessInfo.address.country = address.country;
        }
    },
    mounted(){
        if(this.businessInfoExt && Object.keys(this.businessInfoExt).length){
            // this.businessInfo = this.businessInfoExt;
            this.editBusinesssInfo = true;
            let value = structuredClone(this.businessInfoExt)
                for (const key in value) {
                    if (!this.businessInfo.hasOwnProperty(key)) {
                            // Delete the keys not in use from the value
                            delete value[key];
                    }
                }

                    // Delete extra fields in the address object
                for (const key in value) {
                    if (!this.businessInfo.address.hasOwnProperty(key)) {
                        delete value.address[key];
                }
            }
            this.businessInfo = { ...value };
        }
    }
  }
  </script>
  
  <style scoped>
   .cancel{
          background: #EEF2F6;
          color: #697586;
      }
      input, select{
          background: #E3E8EF;
          color:#9AA4B2;
          border: 1px solid  #E3E8EF;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: left;
  
      }
      .continue-Btn{
          background:#FCFCFD;
          border-top: 1px solid #E3E8EF;
      }
      .opt{
          color: #697586;
      }
      .skip{
          color: #6F00FF;
      }
      .continue-Btn-area{
          min-height: 80px;
          border-top: 1px solid #E3E8EF
      }
     
      input:focus{
          border-color:none ;
      }
      .create-acct{
          font-family: Inter;
          font-size: 13px;
          font-weight: 400;
          line-height: 15.6px;
          text-align: left;
          color: #524F58;
      }
      .business-type{
          background: #E3E8EF;
      }
      .Business{
          color: #202939;
          font-weight: 400;
          line-height: 34.8px;
      }
  </style>